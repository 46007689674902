<style>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }
</style>
<template>
  <div>
     <Form label-position="top">
      <Row :gutter="8">
        <i-col span="24">
          <FormItem label="对账状态">
           <RadioGroup type="button" size="small" v-model="status">
          <Radio
            v-for="(item, index) in confirmStatus"
            :key="index"
            :label="item.id"
            >{{ item.name }}</Radio
          >
        </RadioGroup>
          </FormItem>
        </i-col>
         <i-col span="24">
          <FormItem label="作业类型">
             <Row class="p-b-5">
              <i-col span="24">
                  <div v-for="(item, index) in taskitemTypeArray" :key="index" class="p-l-10 p-1-10 m-b-2" @click="changeChecked('taskitemType',item.taskitemType)"
                      :class="chkTaskitemTypes.includes(item.taskitemType)?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.taskitemTypeText}}</div>
              </i-col>
          </Row>
           <!-- <RadioGroup type="button" size="small" v-model="taskitemTypeId">
            <Radio
              v-for="(item, index) in taskitemTypeArray"
              :key="index"
              :label="item.taskitemType"
              >{{ item.taskitemTypeText }}</Radio
            >
          </RadioGroup> -->
          </FormItem>
        </i-col>
         <i-col span="24">
          <FormItem label="资源类型">
            <Row class="p-b-5">
              <i-col span="24">
                  <div v-for="(item, index) in resourceTypeArray" :key="index" class="p-l-10 p-1-10 m-b-2" @click="changeChecked('resourcetype',item.resourcetypeId)"
                      :class="chkResourceTypes.includes(item.resourcetypeId)?'workplatform-radio-button-active':'workplatform-radio-button'">{{item.resourcetypeText}}</div>
              </i-col>
          </Row>

          <!-- <RadioGroup type="button" size="small" v-model="resourceTypeId">
          <Radio
            v-for="(item, index) in resourceTypeArray"
            :key="index"
            :label="item.resourcetypeId"
            >{{ item.resourcetypeText }}</Radio
          >
        </RadioGroup> -->
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="记账周期">
          <Row :gutter="8">

            <i-col span="12" class="p-b-5">
        <DatePicker
          placeholder="起始日期"
          v-model="startDate"
          type="date"
          size="small"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col span="12" class="p-b-5">
        <DatePicker
          placeholder="结束日期"
          type="date"
          v-model="endDate"
          size="small"
          placement="bottom-end"
          style="width: 100%"
        ></DatePicker>
      </i-col>
          </Row>
          </FormItem>
        </i-col>
        <i-col span="24" >
          <FormItem label="公司">
            <Select v-model="currentsettlementCompanyId" size="small" >
          <Option
            v-for="(company, index) in settlementcompanyArray"
            :key="index"
            :value="company.companyId"
            >{{ company.companyName }}</Option
          >
        </Select>
          </FormItem>
        </i-col>
         <i-col span="24" >
          <FormItem label="供应商">
            <Select v-model="currentsupplierCompanyId" size="small" >
          <Option
            v-for="(company, index) in suppliercompanyArray"
            :key="index"
            :value="company.companyId"
            >{{ company.companyName }}</Option
          >
        </Select>
          </FormItem>
        </i-col>
         <i-col span="24">
          <FormItem label="作业周期">
          <Row :gutter="8">

            <i-col span="12" class="p-b-5">
        <DatePicker
          placeholder="起始日期"
          type="date"
          v-model="startActionDate"
          size="small"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col span="12" class="p-b-5">
        <DatePicker
          placeholder="结束日期"
          type="date"
          v-model="endActionDate"
          placement="bottom-end"
          size="small"
          style="width: 100%"
        ></DatePicker>
      </i-col>
          </Row>
          </FormItem>

      </i-col>
       <i-col span="24">
          <FormItem label="计费区间">
          <Row :gutter="8">

            <i-col span="12" class="p-b-5">
        <i-input
          type="number"
          size="small"
          v-model="minCost"
          clearable
          placeholder="最低计费金额"
        ></i-input>
      </i-col>
      <i-col span="12" class="p-b-5">
        <i-input
          type="number"
          size="small"
          v-model="maxCost"
          clearable
          placeholder="最高计费金额"
        ></i-input>
      </i-col>
          </Row>
          </FormItem>

      </i-col>
        <i-col span="24" >
          <FormItem  label="关键字">
           <Row :gutter="8">
              <i-col span="18">
       <i-input
          type="text"
          size="small"
          v-model="keyword"
          clearable
          placeholder="如：客户、品牌、编码"
        ></i-input>
      </i-col>
      <i-col span="6">
        <Button
          type="primary"
          icon="ios-search"
          long
          size="small"
          :loading="loadSearch"
          @click="searchmaintenanceReconciliationList"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
      </i-col>
           </Row>
          </FormItem>
        </i-col>
      </Row>

      <FormItem class="text-right">
      </FormItem>
    </Form>
    <Divider dashed><span class="divider-text">其他操作</span></Divider>
    <div class="m-b-5" >
      <Button
        type="primary"
        v-if="isAuth('maintenance_confirmReconciliation')"
          size="small"
        @click="confirmReconcilliation"
        :disabled="
          feeitemIds.length === 0 || notAllowReconciliationQuantity > 0
        "
      >
        确认对账</Button
      >
       <Button type="success" class="m-l-5"
          size="small" @click="showDownLoadProgrammeModal = true">下载列表</Button>
    </div>
    <Modal v-model="showDownLoadProgrammeModal">
      <div class="workplatform-title" slot="header">请选择下载类型</div>
      <Row>
        <i-col span="24">
          <RadioGroup v-model="downLoadProgramme" vertical>
            <Radio label="1">
                <span>全部下载</span>
            </Radio>
            <Radio label="2">
                <span>勾选下载</span>
            </Radio>
        </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text"  @click="showDownLoadProgrammeModal=false">取消</Button>
        <Button type="primary"  @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  gettaskitemtypelist,
  getresourcetypelist,
  getsettlements,
  getsuppliers
} from '@/api/msp/settlement'
import { confirmfeeitems } from '@/api/statement/bill'
import { ParseDate, ParseDateForMonth } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
export default {
  data () {
    return {
      confirmStatus: [
        { id: -1, name: '全部' },
        { id: 1, name: '未对账' },
        { id: 2, name: '已对账' }
      ],
      status: -1,

      loadSearch: false,
      optionArray: [],
      settlementStatusList: [],
      companyArray: [],
      taskitemTypeArray: [],
      taskitemTypeId: null,
      chkTaskitemTypes: [-1],

      resourceTypeArray: [],
      resourceTypeId: null,
      chkResourceTypes: [-1],

      startDate: '',
      endDate: '',
      startActionDate: '',
      endActionDate: '',
      keyword: '',
      minCost: null,
      maxCost: null,

      settlementcompanyArray: [],
      suppliercompanyArray: [],
      currentsupplierCompanyId: 0,
      currentsettlementCompanyId: 0,
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1'
    }
  },
  created () {
    this.inittaskitemTypeArray()
  },
  methods: {

    // 加载作业类型
    inittaskitemTypeArray () {
      const that = this
      that.taskitemTypeArray = []

      gettaskitemtypelist({
        settlementStatus: that.status === -1 ? null : that.status
      }).then((res) => {
        that.taskitemTypeArray = res
        that.initresourceTypeArray()
      })
    },
    // 变更多选框选择
    changeChecked (key, value) {
      const that = this
      switch (key) {
        case 'taskitemType':
          if (value === -1) {
            that.chkTaskitemTypes = [-1]
          } else {
            const index = that.chkTaskitemTypes.indexOf(-1)
            if (index > -1) {
              that.chkTaskitemTypes.splice(index, 1)
            }

            if (that.chkTaskitemTypes.includes(value)) {
              const index1 = that.chkTaskitemTypes.indexOf(value)
              if (index1 > -1) {
                that.chkTaskitemTypes.splice(index1, 1)
              }
            } else {
              that.chkTaskitemTypes.push(value)
            }
          }
          that.initresourceTypeArray()
          break
        case 'resourcetype':
          if (value === -1) {
            that.chkResourceTypes = [-1]
          } else {
            const index = that.chkResourceTypes.indexOf(-1)
            if (index > -1) {
              that.chkResourceTypes.splice(index, 1)
            }

            if (that.chkResourceTypes.includes(value)) {
              const index1 = that.chkResourceTypes.indexOf(value)
              if (index1 > -1) {
                that.chkResourceTypes.splice(index1, 1)
              }
            } else {
              that.chkResourceTypes.push(value)
            }
          }
          break
      }
    },
    // 加载资源类型
    initresourceTypeArray () {
      const that = this
      that.resourceTypeArray = []
      let taskitemTypeStr = null
      if (that.chkTaskitemTypes.length && !that.chkTaskitemTypes.includes(-1)) {
        taskitemTypeStr = that.chkTaskitemTypes.toString()
      }
      getresourcetypelist({
        settlementStatus: that.status === -1 ? null : that.status,
        taskitemTypes: taskitemTypeStr// that.taskitemTypeId === -1 ? null : that.taskitemTypeId
      }).then((res) => {
        that.resourceTypeArray = res
        that.resourceTypeId = that.resourceTypeArray[0].resourcetypeId

        this.initsettlementCompanyArray()
      })
    },
    // 获取结算方公司列表
    initsettlementCompanyArray () {
      getsettlements({ }).then((res) => {
        this.settlementcompanyArray = res
        if (this.settlementcompanyArray.length) {
          this.currentsettlementCompanyId = this.maintenanceReconciliationQuery.settlementCompanyId || this.settlementcompanyArray[0].companyId
        }
        this.inisupplierCompanyArray()
      })
    },
    // 获取供应商公司列表
    inisupplierCompanyArray () {
      getsuppliers({ }).then(
        (res) => {
          this.suppliercompanyArray = res
          if (this.suppliercompanyArray.length) {
            this.currentsupplierCompanyId = this.maintenanceReconciliationQuery.supplierCompanyId || this.suppliercompanyArray[0].companyId
          }
          this.searchmaintenanceReconciliationList()
        }
      )
    },

    // 搜索列表
    searchmaintenanceReconciliationList () {
      var query = {
        settlementCompanyId: this.currentsettlementCompanyId,
        supplierCompanyId: this.currentsupplierCompanyId,
        status: this.status, // 对账状态
        taskitemType: this.taskitemTypeId,
        resourcetypeId: this.resourceTypeId
      }

      if (this.startDate) {
        query.startDate = ParseDate(this.startDate)
      }
      if (this.endDate) {
        query.endDate = ParseDate(this.endDate)
      }
      if (this.startActionDate) {
        query.startActionDate = ParseDate(this.startActionDate)
      }
      if (this.endActionDate) {
        query.endActionDate = ParseDate(this.endActionDate)
      }
      if (this.keyword) {
        query.keyword = this.keyword
      }
      if (this.minCost || this.minCost === 0) {
        query.minCost = this.minCost
      }
      if (this.maxCost || this.maxCost === 0) {
        query.maxCost = this.maxCost
      }
      if (this.chkTaskitemTypes.length && !this.chkTaskitemTypes.includes(-1)) {
        query.taskitemTypes = this.chkTaskitemTypes.toString()
      }
      if (this.chkResourceTypes.length && !this.chkResourceTypes.includes(-1)) {
        query.resourcetypeIds = this.chkResourceTypes.toString()
      }

      // if (this.executeStatus !== -1) { query.executeStatus = this.executeStatus }
      this.$store.commit(
        'set_reconciliationNew_maintenanceReconciliationQuery',
        query
      )
      this.$store.commit('set_reconciliationNew_update', new Date())
    },
    confirmReconcilliation () {
      const that = this
      if (!that.feeitemIds.length) {
        that.$Notice.info({ desc: '请选择要对账的条目！' })
      } else {
        that.$Modal.confirm({
          title: '操作确认',
          content: '<p>是否要将所选条目标记为已对账，确认后<span style="color:red;font-weight:600;">无法再次变更</span>，请谨慎操作！</p>',
          loading: true,
          onOk: () => {
            confirmfeeitems({
              feeitemListJsonStr: JSON.stringify(that.feeitemIds),
              feeitemType: 2
            }).then((res) => {
              if (res && !res.errcode) {
                that.$store.commit('set_reconciliationNew_feeitemIds', [])
                that.$store.commit('set_reconciliationNew_update', new Date())
              }
              that.$Modal.remove()
            }).catch(() => {
              that.$Modal.remove()
            })
          }
        })
      }
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {}
      if (parseInt(that.downLoadProgramme) === 1) {
        params.publisherId = this.publisherId
        params.supplierCompanyId = this.maintenanceReconciliationQuery.supplierCompanyId
        params.settlementCompanyId = this.maintenanceReconciliationQuery.settlementCompanyId
        params.startDate = this.maintenanceReconciliationQuery.startDate
        params.endDate = this.maintenanceReconciliationQuery.endDate
        params.startActionDate = this.maintenanceReconciliationQuery.startActionDate
        params.endActionDate = this.maintenanceReconciliationQuery.endActionDate
        params.keyword = this.maintenanceReconciliationQuery.keyword
        params.minCost = this.maintenanceReconciliationQuery.minCost
        params.maxCost = this.maintenanceReconciliationQuery.maxCost
        params.status = this.maintenanceReconciliationQuery.status === -1 ? null : this.maintenanceReconciliationQuery.status

        params.taskitemTypes = (that.chkTaskitemTypes.length && !that.chkTaskitemTypes.includes(-1)) ? that.chkTaskitemTypes.toString() : null
        params.resourcetypeIds = (that.chkResourceTypes.length && !that.chkResourceTypes.includes(-1)) ? that.chkResourceTypes.toString() : null

        // params.taskitemType = this.maintenanceReconciliationQuery.taskitemType === -1 ? null : this.maintenanceReconciliationQuery.taskitemType
        // params.resourcetypeId = this.maintenanceReconciliationQuery.resourcetypeId === -1 ? null : this.maintenanceReconciliationQuery.resourcetypeId
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.taskitemChargeIds = JSON.stringify(that.feeitemIds)
        }
      }

      that.downLoadExecl(params)
    },
    // 下载列表
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr = ParseDateForMonth(params.startDate) + '至' + ParseDateForMonth(params.endDate)
        }
      }
      const filename = '运维对账明细(' + dateStr + ').xlsx'
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
            '/ooh-msp/v2/msppreport/downloadsettlementdetail',
        params,
        filename
      )
    }
  },
  computed: {
    companyId () {
      return this.$store.state.reconciliationNew.companyId
    },
    publisherId () {
      return this.$store.state.reconciliationNew.publisherId
    },
    // transactionReconciliationQuery () {
    //   return this.$store.state.reconciliationNew.transactionReconciliationQuery
    // },
    feeitemIds () {
      return this.$store.state.reconciliationNew.feeitemIds || []
    },
    notAllowReconciliationQuantity () {
      return (
        this.$store.state.reconciliationNew.notAllowReconciliationQuantity || 0
      )
    },
    maintenanceReconciliationQuery () {
      return this.$store.state.reconciliationNew.maintenanceReconciliationQuery
    }
  },
  watch: {
    status () {
      this.inittaskitemTypeArray()
    }
  }
}
</script>
